import GlobalPreloader from 'components/GlobalPreloader'
import { useTokenSales } from '../../hooks/useTokenSales'
import GridTokenSalesTable from 'components/GridTokenSalesTable'

const TokenSale = () => {
  const { tokenSales, isTokenSalesLoading, error } = useTokenSales()

  if (isTokenSalesLoading) {
    return <GlobalPreloader />
  }

  if (error) {
    return <div>
      Error:
      {' '}
      {error}
    </div>
  }

  return (
    <>
      <h2>
        Tokensales (
        {tokenSales.length}
        )
      </h2>
      <GridTokenSalesTable tokenSales={tokenSales} />
    </>
  )
}

export default TokenSale
