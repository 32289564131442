import StatsCard from '../../components/StatsCard'
import styles from './styles.module.scss'
import StubIcon from '../../assets/images/stub-stat-icon.svg'
import { useUsers } from '../../hooks/useUsers'
import { useTokenSales } from '../../hooks/useTokenSales'
import GridTokenSalesTable from 'components/GridTokenSalesTable'
import GlobalPreloader from 'components/GlobalPreloader'
import InfoBanner from 'components/InfoBanner'

const Dashboard = () => {
  const { users, usersIsLoading, userError } = useUsers()
  const { tokenSales, isTokenSalesLoading, tokenSalesError } = useTokenSales()

  if (usersIsLoading || isTokenSalesLoading) {
    return <GlobalPreloader />
  }

  if (tokenSalesError || userError) {
    return <div>Error</div>
  }

  return (
    <div>
      <div className={styles.statsWrapper}>
        <StatsCard
          title="Total Users"
          iconUrl={StubIcon}
          statNumber={users.length}
          linkName="See all"
          linkUrl="/users"
        />
        <StatsCard
          title="Active Token Sales"
          iconUrl={StubIcon}
          statNumber={tokenSales.length}
          linkName="See all"
          linkUrl="/tokensales"
        />
        <StatsCard
          title="Transactions"
          iconUrl={StubIcon}
          statNumber={120652}
          linkName="See all"
          linkUrl="/transactions"
        />
      </div>
      <InfoBanner />
      <GridTokenSalesTable tokenSales={tokenSales} />
    </div>
  )
}

export default Dashboard
