import { useState, useEffect } from 'react'
import AuthService from '../api/AuthService'

const useSession = () => {
  const [session, setSession] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const data = await AuthService.getSession()
        setSession(data)
      } catch (error) {
        setError('Failed to fetch session')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchSession()
  }, [])

  return { session, isLoading, error }
}

export default useSession