import api from './api'

const AuthService = {
  login: async (login, password) => {
    const response = await api.post('/login', { login, password })
    return response.data.data
  },
  getSession: async () => {
    const response = await api.get('/session')
    return response.data.data
  },
}

export default AuthService