import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './styles.module.scss'
import TokenSaleService from '../../api/TokenSaleService'
import HintIcon from '../../assets/images/hint-icon.svg'
import ModalUi from '../../components/ModalUi'
import CustomInputUi from 'components/CustomInput'
import useFormValidator from '../../hooks/useFormValidator'
import tokenSaleValidator from '../../validators/tokenSaleValidator'
import ThumbnailUploader from 'components/ThumbnailUploader'
import ImagesUploader from 'components/ImagesUploader'
import DocumentsUploader from 'components/DocumentsUploader'

const NewTokenSale = () => {
  const { inputErrors, setInputErrors, isFormValid } =
    useFormValidator(tokenSaleValidator)

  const [formState, setFormState] = useState({
    title: '',
    description: '',
    address: '',
    city: '',
    country: '',
    tokenTotalSupply: '',
    tokenPrice: '',
    saleMinBuy: '',
    saleMaxBuy: '',
    saleStartAt: '',
    saleEndAt: '',
    rentAmount: '',
    projectedAnnualReturn: '',
    projectedRentalYield: '',
    rentalYield: '',
    beneficiaryAddress: '',
  })

  const [thumbnail, setThumbnail] = useState(null)
  const [images, setImages] = useState([])
  const [documents, setDocuments] = useState([])

  const [musharakahAllowed, setMusharakahAllowed] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(true)

  const handleInputChange = ({ target }) => {
    const { name, value } = target
    setFormState((prevData) => ({ ...prevData, [name]: value }))
    setInputErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
  }

  const handleThumbnailChange = (newThumbnail) => {
    setThumbnail(newThumbnail)
  }

  const handleImagesChange = (newImages) => {
    setImages(newImages)
  }

  const handleDocumentsChange = (newDocuments) => {
    setDocuments(newDocuments)
  }

  useEffect(() => {
    setFormState(prevState => ({
      ...prevState,
      saleMaxBuy: prevState.tokenTotalSupply,
    }))
  }, [formState.tokenTotalSupply])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setError('')

    if (!isFormValid(formState)) {
      return
    }
    setIsLoading(true)

    try {
      openModal()
      await TokenSaleService.createTokenSale(
        formState,
        musharakahAllowed,
        thumbnail,
        images,
        documents,
      )
      setTimeout(() => {
        setIsModalOpen(false)
        navigate('/tokensales')
      }, '5000')
    } catch (error) {
      if (error.response) {
        setError(JSON.stringify(error.response.data))
      } else {
        console.log(error)
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false)
        setIsModalOpen(false)
      }, '5000')
    }
  }

  return (
    <>
      <h2>Create new TokenSale</h2>
      <div className={styles.wrapper}>
        <div className={styles.commonForm}>
          <div className={styles.mainSection}>
            <p className={styles.header}>Main Information</p>
            <p className={styles.description}>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
            <div className={styles.formSection}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div onClick={() => setMusharakahAllowed(false)} className={`${styles.selector} ${!musharakahAllowed ? styles.active : ''}`}>
                  <p className={styles.selectorTitle}>Standard tokensale</p>
                  <p className={styles.selectorDesc}>
                    Provides only for investors to receive profits from rentals.
                    {' '}
                    <a href="#">Read more</a>
                  </p>
                </div>
                <div onClick={() => setMusharakahAllowed(true)} className={`${styles.selector} ${musharakahAllowed ? styles.active : ''}`}>
                  <p className={styles.selectorTitle}>Musharakah tokensale</p>
                  <p className={styles.selectorDesc}>
                    Allows property owners to additionally purchase tokens from investors.
                    {' '}
                    <a href="#">Read more</a>
                  </p>
                </div>
                <CustomInputUi
                  label="Title"
                  name="title"
                  value={formState.title}
                  placeholder="Title"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.title}
                  autoComplete="off"
                  hint="Example: Amazing property In Paris"
                />

                <CustomInputUi
                  label="Description"
                  name="description"
                  value={formState.description}
                  placeholder="Description"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.description}
                  autoComplete="off"
                  hint="Example: Very amazing property! You will not regret!"
                />

                <CustomInputUi
                  label="Address"
                  name="address"
                  value={formState.address}
                  placeholder="Address"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.address}
                  autoComplete="off"
                  hint="Example: St. Lui 9"
                />

                <CustomInputUi
                  label="City"
                  name="city"
                  value={formState.city}
                  placeholder="City"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.city}
                  autoComplete="off"
                  hint="Example: Paris"
                />

                <CustomInputUi
                  label="Country"
                  name="country"
                  value={formState.country}
                  placeholder="Country"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.country}
                  autoComplete="off"
                  hint="Example: France"
                />

                <CustomInputUi
                  label="Token Total Supply"
                  name="tokenTotalSupply"
                  value={formState.tokenTotalSupply}
                  placeholder="Token Total Supply"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.tokenTotalSupply}
                  autoComplete="off"
                  hint="The minimum quantity must be greater than or equal to 100"
                />

                <CustomInputUi
                  label="Token Price (in DNR)"
                  name="tokenPrice"
                  value={formState.tokenPrice}
                  placeholder="Token Price"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.tokenPrice}
                  autoComplete="off"
                  hint="Example: 50"
                />

                <CustomInputUi
                  label="Minimum number of tokens for purchase"
                  name="saleMinBuy"
                  value={formState.saleMinBuy}
                  placeholder="Min amount"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.saleMinBuy}
                  autoComplete="off"
                  hint="Example: 1"
                />

                {/* <CustomInputUi
                  label="Maximum number of tokens for purchase"
                  name="saleMaxBuy"
                  value={formState.saleMaxBuy}
                  placeholder="Max amount"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.saleMaxBuy}
                  autoComplete="off"
                  hint="Example: 1"
                /> */}

                <CustomInputUi
                  label="TokenSale start date"
                  name="saleStartAt"
                  value={formState.saleStartAt}
                  placeholder=""
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.saleStartAt}
                  type="date"
                  autoComplete="off"
                  hint="Example: 2023-12-02"
                />

                <CustomInputUi
                  label="TokenSale end date"
                  name="saleEndAt"
                  value={formState.saleEndAt}
                  placeholder=""
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.saleEndAt}
                  type="date"
                  autoComplete="off"
                  hint="Example: 2023-12-02"
                />

                <CustomInputUi
                  label="Projected Annual Return (%)"
                  name="projectedAnnualReturn"
                  value={formState.projectedAnnualReturn}
                  placeholder="Projected Annual Return"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.projectedAnnualReturn}
                  autoComplete="off"
                  hint="Example: 10.23"
                />

                <CustomInputUi
                  label="Rent amount in DNR"
                  name="rentAmount"
                  value={formState.rentAmount}
                  placeholder="Rent amount"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.rentAmount}
                  type="text"
                  autoComplete="off"
                  hint="Example: 2500"
                />

                <CustomInputUi
                  label="Projected Rental Yield (%)"
                  name="projectedRentalYield"
                  value={formState.projectedRentalYield}
                  placeholder="Projected Rental Return"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.projectedRentalYield}
                  autoComplete="off"
                  hint="Example: 8.98"
                />

                <CustomInputUi
                  label="Rental Yield (DNR)"
                  name="rentalYield"
                  value={formState.rentalYield}
                  placeholder="Rental Yield"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.rentalYield}
                  autoComplete="off"
                  hint="Example: 4500"
                />

                <CustomInputUi
                  label="Beneficiary address"
                  name="beneficiaryAddress"
                  value={formState.beneficiaryAddress}
                  placeholder="Beneficiary Address"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.beneficiaryAddress}
                  autoComplete="off"
                  hint="Example: 0x44d9d1f07eF730b88406B2AE70021517Bf1d9EeD"
                />

                <ThumbnailUploader onThumbnailChange={handleThumbnailChange} />

                <ImagesUploader onImagesChange={handleImagesChange} />

                <DocumentsUploader onDocumentsChange={handleDocumentsChange} />

                <hr className={styles.separator} />
                {
                  Object.values(inputErrors).some(value => value !== '') &&
                  <div className={styles.errorWrapper}>Correct the errors mentioned above</div>
                }
                {error && <div className={styles.errorWrapper}>{error}</div>}
                <button
                  className={styles.submitBtn}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? 'Creating...' : 'Create TokenSale Request'}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className={styles.additional}>
          <div className={styles.helpSection}>
            <img src={HintIcon} alt="" />
            <div className={styles.title}>
              Title for the section with additional information
            </div>
            <div className={styles.desc}>
              {'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\', making it look like readable English.'}
            </div>
          </div>
        </div>
      </div>

      <ModalUi isOpen={isModalOpen} onClose={closeModal}>
        <div className={styles.modalWrapper}>
          <h4 className={styles.modalHeader}>
            We are creating a token sale...
          </h4>
          <div className={styles.modalDescription}>
            <p>At this point we create a smart contract on the blockchain,</p>
            <p>which usually takes about 5 seconds.</p>
            <p>
              After successful creation, you will be redirected to the token
              sale page.
            </p>
          </div>
        </div>
      </ModalUi>
    </>
  )
}

export default NewTokenSale
