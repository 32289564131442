import styles from './styles.module.scss'
// import AptIcon from '../../assets/images/house-type-icons/apt.svg'
// import HouseIcon from '../../assets/images/house-type-icons/house.svg'
import BackgroundImage from '../BackgroundImage'

import { formatNative } from 'utils/formatNumber'
import { NavLink } from 'react-router-dom'

// const TYPES = {
//   house: {
//     text: 'House',
//     icon: AptIcon,
//   },
//   apartment: {
//     text: 'Flat/apartment',
//     icon: HouseIcon,
//   },
// }

const RequestedTokenSaleUi = ({ id, address, location, price, imageUrl }) => {
  // const typeData = TYPES[type]

  return (
    <NavLink to={`/tokensale-request/${id}`}>
      <div className={styles.wrapper}>

        <div className={styles.mainInfo}>
          <div className={styles.bgImageWrapper}>
            <BackgroundImage imageUrl={imageUrl} />
          </div>
          <div className={styles.tokenSaleData}>
            {/* <div className={styles.type}>
              <img src={typeData.icon} />
              {' '}
              {typeData.text}
            </div> */}
            <p className={styles.address}>{address}</p>
            <p className={styles.location}>{location}</p>
            <p className={styles.price}>
              {`${formatNative(price)} DNR`}
            </p>
          </div>
        </div>


        {/* <div className={styles.actions}>
        <div className={styles.approveBtn}>Approve</div>
        <div className={styles.moreInfo}>More Info</div>
      </div> */}
      </div>
    </NavLink>
  )
}

export default RequestedTokenSaleUi
