import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token')

  if (!token) {
    return (
      <Navigate
        to="/login"
        state={{ error: 'Please log in to continue' }}
        replace
      />
    )
  }

  return children
}

export default ProtectedRoute
