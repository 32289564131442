import CustomTable from '../../components/CustomTableUi'

const Transactions = () => {
  const columns = [
    { 'Hash': 80 },
    { 'Method': 120 },
    { 'Block': 120 },
    { 'Age': 150 },
    { 'From': 200 },
    { 'To': 200 },
    { 'Value': 150 },
  ]

  const data = [
    {
      'Hash': { text: '0xb9a34...e8f21', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Transfer' },
      'Block': { text: '152102', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '15 secs ago' },
      'From': { text: '0xc945A...471DE', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9f2B6...579B3', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.021 NBC' },
    },
    {
      'Hash': { text: '0xb8f91...d7f45', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Deposite' },
      'Block': { text: '152103', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '45 secs ago' },
      'From': { text: '0xc975B...572DC', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9e1C5...479A7', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.030 NBC' },
    },
    {
      'Hash': { text: '0xb7e83...c8f23', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Withdrawal' },
      'Block': { text: '152104', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '2 mins ago' },
      'From': { text: '0xca35D...674FA', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9d3F6...270B5', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.012 NBC' },
    },
    {
      'Hash': { text: '0xb6d72...b9f34', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Approve' },
      'Block': { text: '152105', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '5 mins ago' },
      'From': { text: '0xcb47E...875BC', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9c4A7...162D4', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.045 NBC' },
    },
    {
      'Hash': { text: '0xb5c61...a8e25', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Transfer' },
      'Block': { text: '152106', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '8 mins ago' },
      'From': { text: '0xcc59F...976CD', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9b5B8...053E3', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.018 NBC' },
    },
    {
      'Hash': { text: '0xb4b50...97d16', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Withdrawal' },
      'Block': { text: '152107', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '12 mins ago' },
      'From': { text: '0xcd6BA...877DE', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9a6C9...944F2', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.025 NBC' },
    },
    {
      'Hash': { text: '0xb3a4f...86c07', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Deposite' },
      'Block': { text: '152108', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '20 mins ago' },
      'From': { text: '0xce7CB...778EF', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x997DA...835G1', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.040 NBC' },
    },
    {
      'Hash': { text: '0xb293e...75b98', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Approve' },
      'Block': { text: '152109', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '28 mins ago' },
      'From': { text: '0xcf8DC...679FG', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x988EB...726H2', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.033 NBC' },
    },
    {
      'Hash': { text: '0xb182d...64a89', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Transfer' },
      'Block': { text: '152110', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '35 mins ago' },
      'From': { text: '0xd097E...570HI', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x979FC...617J3', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.029 NBC' },
    },
    {
      'Hash': { text: '0xb071c...53z90', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Withdrawal' },
      'Block': { text: '152111', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '42 mins ago' },
      'From': { text: '0xd1a9F...461JK', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x961AD...508K4', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.036 NBC' },
    },
    {
      'Hash': { text: '0xb9a34...e8f21', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Transfer' },
      'Block': { text: '152102', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '15 secs ago' },
      'From': { text: '0xc945A...471DE', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9f2B6...579B3', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.021 NBC' },
    },
    {
      'Hash': { text: '0xb8f91...d7f45', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Deposite' },
      'Block': { text: '152103', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '45 secs ago' },
      'From': { text: '0xc975B...572DC', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9e1C5...479A7', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.030 NBC' },
    },
    {
      'Hash': { text: '0xb7e83...c8f23', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Withdrawal' },
      'Block': { text: '152104', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '2 mins ago' },
      'From': { text: '0xca35D...674FA', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9d3F6...270B5', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.012 NBC' },
    },
    {
      'Hash': { text: '0xb6d72...b9f34', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Approve' },
      'Block': { text: '152105', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '5 mins ago' },
      'From': { text: '0xcb47E...875BC', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9c4A7...162D4', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.045 NBC' },
    },
    {
      'Hash': { text: '0xb5c61...a8e25', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Transfer' },
      'Block': { text: '152106', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '8 mins ago' },
      'From': { text: '0xcc59F...976CD', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9b5B8...053E3', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.018 NBC' },
    },
    {
      'Hash': { text: '0xb4b50...97d16', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Withdrawal' },
      'Block': { text: '152107', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '12 mins ago' },
      'From': { text: '0xcd6BA...877DE', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x9a6C9...944F2', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.025 NBC' },
    },
    {
      'Hash': { text: '0xb3a4f...86c07', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Deposite' },
      'Block': { text: '152108', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '20 mins ago' },
      'From': { text: '0xce7CB...778EF', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x997DA...835G1', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.040 NBC' },
    },
    {
      'Hash': { text: '0xb293e...75b98', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Approve' },
      'Block': { text: '152109', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '28 mins ago' },
      'From': { text: '0xcf8DC...679FG', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x988EB...726H2', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.033 NBC' },
    },
    {
      'Hash': { text: '0xb182d...64a89', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Transfer' },
      'Block': { text: '152110', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '35 mins ago' },
      'From': { text: '0xd097E...570HI', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x979FC...617J3', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.029 NBC' },
    },
    {
      'Hash': { text: '0xb071c...53z90', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Method': { text: 'Withdrawal' },
      'Block': { text: '152111', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Age': { text: '42 mins ago' },
      'From': { text: '0xd1a9F...461JK', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'To': { text: '0x961AD...508K4', link: 'http://164.92.86.68/tx/0xb82784248e5d4f054ec08f9b26f9c43eb3f7f30d2366c4956bb9ec640eaaeaca' },
      'Value': { text: '0.036 NBC' },
    },
  ]

  return (
    <div>
      <h2>Transactions (20)</h2>
      {/* <div className={styles.statsWrapper}>
        <StatsCard
          title="Total Users"
          iconUrl={StubIcon}
          statNumber={1464}
          linkName="See all"
          linkUrl="/users"
        />
        <StatsCard
          title="Active Token Sales"
          iconUrl={StubIcon}
          statNumber={24}
          linkName="See all"
          linkUrl="/tokensales"
        />
        <StatsCard
          title="Transactions"
          iconUrl={StubIcon}
          statNumber={120652}
          linkName="See all"
          linkUrl="/transactions"
        />
      </div> */}
      {/* <FloatingLabelInput
        name="address"
        hint="Адрес"
        placeholder="Например, Нахимов 12"
      /> */}
      <CustomTable width="100%" columns={columns} data={data} />
    </div>
  )
}

export default Transactions