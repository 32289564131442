import SidebarMenu from '../SidebarMenu'
import { Outlet } from 'react-router-dom'
import styles from './styles.module.scss'
import HeaderMenu from '../HeaderMenu'
import MainContainerUi from '../MainContainerUi'

const Layout = () => {
  return (
    <div className={styles.wrapper}>
      <SidebarMenu />
      <div className={styles.rightSection}>
        <HeaderMenu />
        <MainContainerUi>
          <Outlet />
        </MainContainerUi>
      </div>
    </div>

  )
}

export default Layout