import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from './styles.module.scss'
import AuthService from '../../api/AuthService'

const LoginForm = () => {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isErrorShown, setIsErrorShown] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.state?.error) {
      setError(location.state.error)
      navigate(location.pathname, { replace: true, state: {} })
    }
  }, [location, navigate])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setError('')
    setIsErrorShown(false)
    setIsLoading(true)

    if (!login || !password) {
      setError('Please fill in all fields.')
      setIsErrorShown(true)
      setIsLoading(false)
      return
    }

    try {
      const data = await AuthService.login(login, password)
      if (data.token) {
        localStorage.setItem('token', data.token)
        navigate('/dashboard')
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Login failed')
      setIsErrorShown(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFocus = () => {
    setIsErrorShown(false)
  }

  const inputStyle = isErrorShown ? { borderColor: 'red' } : null

  return (
    <div className={styles.wrapper}>
      <h4>Sign in</h4>
      <p className={styles.description}>to continue to Admin Panel</p>
      <form onSubmit={handleSubmit} className={styles.formWrapper}>
        <div className={styles.inputWrapper}>
          <label>Username:</label>
          <input
            className={styles.formInput}
            type="text"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            onFocus={handleFocus}
            style={inputStyle}
            disabled={isLoading}
          />
        </div>
        <div className={styles.inputWrapper}>
          <label>Password:</label>
          <input
            className={styles.formInput}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={handleFocus}
            style={inputStyle}
            disabled={isLoading}
          />
        </div>
        {error && <div className={styles.errorMessage}>{error}</div>}
        <button
          className={styles.buttonForm}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Login'}
        </button>
      </form>
    </div>
  )
}

export default LoginForm
