import { useState, useRef } from 'react'
import styles from './styles.module.scss'
import UploadDocIcon from 'assets/images/upload-doc.svg'
import CloseIcon from 'assets/images/close.svg'

const DocumentsUploader = ({ onDocumentsChange }) => {
  const [documents, setDocuments] = useState([])
  const inputRef = useRef(null)

  const handleDocumentsClick = () => {
    inputRef.current.click()
  }

  const handleDocumentsChange = (event) => {
    const newDocuments = Array.from(event.target.files)
    setDocuments(prevDocuments => [...prevDocuments, ...newDocuments]) // Добавляем к уже существующим
    if (onDocumentsChange) {
      onDocumentsChange([...documents, ...newDocuments])
    }
  }

  const handleRemoveDocument = (documentToRemove, event) => {
    event.stopPropagation()
    const filteredDocuments = documents.filter(document => document !== documentToRemove)
    setDocuments(filteredDocuments)
    if (onDocumentsChange) {
      onDocumentsChange(filteredDocuments)
    }
  }

  const handleRemoveAllDocuments = () => {
    setDocuments([])
    if (onDocumentsChange) {
      onDocumentsChange([])
    }
  }

  return (
    <div className={styles.inputWrapper}>
      <p className={styles.label}>Documents upload:</p>
      <div className={styles.uploadedDocuments}>
        {documents.length > 0
          ? documents.map((document) => (
            <div className={styles.uploadedDocument} key={document.name}>
              {/* <div className={styles.docIconWrapper}>
                <img src={UploadDocIcon} alt="" />
              </div> */}
              <p>{document.name}</p>
              <div
                className={styles.removeDoc}
                onClick={(e) => handleRemoveDocument(document, e)}
              >
                <img src={CloseIcon} alt="" />
              </div>
            </div>
          ))
          : <div className={styles.uploadAction}>
            <div className={styles.iconWrapper}>
              <img src={UploadDocIcon} alt="" />
            </div>
            <p>
              <span onClick={handleDocumentsClick} className={styles.actionLink}>Click to upload documents</span>
              {' '}
              (PDF)
            </p>
            <p>Max. File Size: 20MB</p>
          </div>}
      </div>
      <input
        type="file"
        ref={inputRef}
        accept=".pdf"
        multiple
        onChange={handleDocumentsChange}
        style={{ display: 'none' }}
      />
      {documents.length > 0
        ? (<div className={styles.bottomLinks}>
          <p onClick={handleDocumentsClick} className={styles.actionLink}>Click to upload more documents</p>
          <p onClick={handleRemoveAllDocuments} className={styles.removeLink}>Remove all documents</p>
        </div>)
        : (<p onClick={handleDocumentsClick} className={styles.actionLink}>Click to upload documents</p>)
      }
    </div>
  )
}

export default DocumentsUploader
