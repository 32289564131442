import { useState, useEffect } from 'react'
import AdminService from '../api/AdminsService'

const useAdmins = () => {
  const [admins, setAdmins] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const data = await AdminService.getAdmins()
        setAdmins(data)
      } catch (error) {
        setError('Failed to fetch admins')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAdmins()
  }, [])

  return { admins, isLoading, error }
}

const useAdmin = (id) => {
  const [admin, setAdmin] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const data = await AdminService.getAdmin(id)
        setAdmin(data)
      } catch (error) {
        setError('Failed to fetch admin')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAdmin()
  }, [])

  return { admin, isLoading, error }
}

export { useAdmins, useAdmin }