import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAdmin } from '../../hooks/useAdmins'
import styles from './styles.module.scss'
import AdminService from '../../api/AdminsService'
import HintIcon from '../../assets/images/hint-icon.svg'
import ModalUi from '../../components/ModalUi'
import GlobalPreloader from 'components/GlobalPreloader'

const EditAdmin = () => {
  const { id } = useParams()
  const { admin, isDataLoading, dataError } = useAdmin(id)

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [modalError, setModalError] = useState('')

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  useEffect(() => {
    if (admin) {
      setLogin(admin.login || '')
      setPassword(admin.password || '')
      setRole(admin.roles || '')
    }
  }, [admin])

  if (isDataLoading) return <GlobalPreloader />
  if (dataError) return <div>
    Error:
    {' '}
    {dataError}
  </div>

  const isFormValid = () => {
    return login && password && role
  }

  if (Array.isArray(role)) setRole(role[0])

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!isFormValid()) {
      setError('All fields are required.')
      return
    }

    setIsLoading(true)
    setError('')

    try {
      await AdminService.updateAdmin(id, password, role)
      navigate('/admins')
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to update admin.')
    } finally {
      setIsLoading(false)
    }
  }

  const deleteAdmin = async (event) => {
    event.preventDefault()

    setIsModalLoading(true)
    setModalError('')

    try {
      await AdminService.deleteAdmin(id)
      navigate('/admins')
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to delete admin.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <h2>
        Update admin
        {' '}
        {admin.login}
      </h2>
      <div className={styles.wrapper}>
        <div className={styles.commonForm}>
          <div className={styles.mainSection}>
            <p className={styles.header}>Main Information</p>
            <p className={styles.description}>
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
            </p>
            <div className={styles.formSection}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className={styles.inputWrapper}>
                  <label>Login:</label>
                  <input
                    disabled
                    autoComplete="off"
                    className={styles.formInput}
                    type="text"
                    value={login}
                    placeholder="Login"
                    onChange={(e) => setLogin(e.target.value)}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <label>Password:</label>
                  <input
                    autoComplete="new-password"
                    className={styles.formInput}
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={isLoading}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <label>Role:</label>
                  <select
                    className={styles.formInput}
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    disabled={isLoading}
                  >
                    <option value="super_admin">Super Admin</option>
                    <option value="admin">Admin</option>
                    <option value="manager">Manager</option>
                  </select>
                </div>

                <hr className={styles.separator} />
                {error && <div className={styles.errorWrapper}>{error}</div>}
                <div className={styles.actionBtns}>
                  <button
                    className={styles.submitBtn}
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Updating...' : 'Update Admin'}
                  </button>
                  <div className={styles.modalBtn} onClick={openModal}>
                    Delete admin
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={styles.additional}>
          <div className={styles.helpSection}>
            <img src={HintIcon} alt="" />
            <div className={styles.title}>
              Title for the section with additional information
            </div>
            <div className={styles.desc}>
              {'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\', making it look like readable English.'}
            </div>
          </div>
        </div>
      </div>
      <ModalUi isOpen={isModalOpen} onClose={closeModal}>
        <div className={styles.modalWrapper}>
          <h4 className={styles.modalHeader}>
            Are you sure you want to remove the admin?
          </h4>
          <p className={styles.modalDescription}>
            This action will be irreversible and all data will be deleted
            forever
          </p>
          <div className={styles.modalActions}>
            {modalError && (
              <div className={styles.errorWrapper}>{modalError}</div>
            )}
            <button onClick={deleteAdmin} className={styles.modalAccept}>
              {isModalLoading
                ? 'Deleting...'
                : 'Yes I want to delete the admin'}
            </button>
            <button onClick={closeModal} className={styles.modalDecline}>
              Cancel
            </button>
          </div>
        </div>
      </ModalUi>
    </>
  )
}

export default EditAdmin
