function BackgroundImage({ imageUrl }) {
  const style = {
    width: '100%',
    height: '100%',
    borderRadius: '6px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${imageUrl})`,
  }

  return <div style={style}></div>
}

export default BackgroundImage