import styles from './styles.module.scss'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTokenSaleRequest } from '../../hooks/useTokenSales'
import { useNavigate } from 'react-router-dom'

import { formatNative } from 'utils/formatNumber'
import ModalUi from 'components/ModalUi'
import TokenSaleService from 'api/TokenSaleService'
import GlobalPreloader from 'components/GlobalPreloader'

const TokenSaleRequest = () => {
  const { id } = useParams()
  const { tokenSaleRequest, isLoading, error } = useTokenSaleRequest(id)

  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [modalError, setModalError] = useState('')

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  if (isLoading) return <GlobalPreloader />
  if (error) return <div>
    Error:
    {' '}
    {error}
  </div>

  // const calculateTimeLeft = (endDate) => {
  //   const today = new Date()
  //   const end = new Date(endDate)
  //   const timeLeft = end - today
  //   const daysLeft = Math.round(timeLeft / (1000 * 60 * 60 * 24))
  //   return daysLeft > 0 ? `${daysLeft} days` : '0 days'
  // }

  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', options)
  }

  function formatString(str) {
    return str
      .split('_') // Разбиваем строку на массив слов
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Делаем первую букву каждого слова заглавной
      .join(' ') // Объединяем слова обратно в строку с пробелами
  }

  function getFileNameFromUrl(url) {
    const parts = url.split('/')
    return parts[parts.length - 1]
  }

  const approveTokenSale = async (event) => {
    event.preventDefault()

    setIsModalLoading(true)
    setModalError('')

    try {
      await TokenSaleService.approveTokenSaleRequest(id)
      console.log('success')
      setTimeout(() => {
        closeModal()
        navigate('/tokensale-requests')
      }, '5000')
    } catch (error) {
      setModalError(error.response?.data?.message || 'Failed to approve tokensale.')
    }
  }

  return (
    <>
      <h2>
        TokenSale request #
        {tokenSaleRequest.id}
      </h2>
      <div className={styles.wrapper}>
        <div className={styles.commonForm}>
          <div className={styles.mainSection}>
            <p className={styles.header}>Tokensale request main info</p>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Public title</p>
              <p className={styles.innerDesc}>
                {' '}
                {tokenSaleRequest.title}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Address</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.address.address}, ${tokenSaleRequest.address.city}, ${tokenSaleRequest.address.country}`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>About the Property</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.description}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Project Annual Return</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.additional_info.projected_annual_return}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Project Rental Yield</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.additional_info.projected_rental_yield}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Rental Yield</p>
              <p className={styles.innerDesc}>
                {`${tokenSaleRequest.additional_info.rental_yield}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Thumbnail</p>
              <p className={styles.innerDesc}><img className={styles.imgWrapper} src={tokenSaleRequest.thumbnail?.url} alt="" /></p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Images</p>
              <div className={styles.innerImages}>
                {
                  tokenSaleRequest.images?.map((image) => (
                    <div key={image.url}>
                      <p className={styles.innerDesc}><img className={styles.imgWrapper} src={image.url} alt="" /></p>
                    </div>
                  ))
                }
              </div>

            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Documents</p>
              <div className={styles.innerImages}>
                {
                  tokenSaleRequest.documents.map((doc) => (
                    <div key={doc.url}>
                      <p className={styles.innerDesc}><a className={styles.innerDoc} href={doc.url}>{getFileNameFromUrl(doc.url)}</a></p>
                    </div>
                  ))
                }
              </div>

            </div>
          </div>
        </div>
        <div className={styles.additional}>
          <div className={styles.helpSection}>
            <div>
              <div className={styles.title}>ID</div>
              <div className={styles.desc}>{tokenSaleRequest.id}</div>
            </div>
            <div>
              <div className={styles.title}>Total tokens</div>
              <div className={styles.desc}>{`${formatNative(tokenSaleRequest.token_info.hardcap)}`}</div>
            </div>
            <div>
              <div className={styles.title}>Price per token</div>
              <div className={styles.desc}>
                {`${formatNative(tokenSaleRequest.token_info.price)} DNR`}
              </div>
            </div>
            {/* <div>
              <div className={styles.title}>Time left</div>
              <div className={styles.desc}>
                {calculateTimeLeft(tokenSaleRequest.token_info.sale_end_at)}
              </div>
            </div> */}
            <div>
              <div className={styles.title}>Tokensale start at</div>
              <div className={styles.desc}>
                {formatDate(tokenSaleRequest.token_info.sale_start_at)}
              </div>
            </div>
            <div>
              <div className={styles.title}>Tokensale end at</div>
              <div className={styles.desc}>
                {formatDate(tokenSaleRequest.token_info.sale_end_at)}
              </div>
            </div>
            <div>
              <div className={styles.title}>Creation date</div>
              <div className={styles.desc}>
                {formatDate(tokenSaleRequest.created_at)}
              </div>
            </div>
            <div>
              <div className={styles.title}>Status</div>
              <div className={styles.desc}>
                {formatString(tokenSaleRequest.status)}
              </div>
            </div>
          </div>

          <div className={styles.submitBtn} onClick={openModal}>
            Approve Token Sale
          </div>
          <div className={styles.actionBtn}>Write to the sender</div>

        </div>
      </div>
      <ModalUi isOpen={isModalOpen} onClose={closeModal}>
        <div className={styles.modalWrapper}>
          <h4 className={styles.modalHeader}>
            Are you sure you want to approve the Token Sale?
          </h4>
          {/* <p className={styles.modalDescription}>
            This action will be irreversible and all data will be deleted
            forever
          </p> */}
          <div className={styles.modalActions}>
            {modalError && (
              <div className={styles.errorWrapper}>{modalError}</div>
            )}
            <button onClick={approveTokenSale} className={styles.modalAccept}>
              {isModalLoading
                ? 'Approving...'
                : 'Yes I want to approve'}
            </button>
            <button onClick={closeModal} className={styles.modalDecline}>
              Cancel
            </button>
          </div>
        </div>
      </ModalUi>
    </>
  )
}

export default TokenSaleRequest
