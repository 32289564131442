import Status from 'components/Status'
import styles from './styles.module.scss'
import { formatDate } from 'utils/formatDate'
import { NavLink } from 'react-router-dom'

const GridUsersTable = ({ users }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.gridTable}>
        <div className={styles.gridHeaderWrapper}>
          <div className={styles.gridHeader}>ID / Email</div>
          <div className={styles.gridHeader}>Full Name</div>
          <div className={styles.gridHeader}>Status</div>
          <div className={`${styles.gridHeader} ${styles.txtRightAlign}`}>Last login</div>
        </div>

        {users.map((user) =>
          (<NavLink className={styles.gridItemWrapper} key={user.id} to={`/user/${user.id}`}>
            <div className={`${styles.gridItem} ${styles.txtLeftAlign}`}>
              <div className={styles.columnWrapper}>
                <p className={styles.id}>{`ID:${user.id}`}</p>
                <p className={styles.title}>{user.email}</p>
              </div>
            </div>
            <div className={styles.gridItem}>{user.full_name}</div>
            <div className={styles.gridItem}><Status status={user.status} /></div>
            <div className={`${styles.gridItem} ${styles.date} ${styles.txtRightAlign}`}>{formatDate(user.last_login_at)}</div>
          </NavLink>),
        )}
      </div>

    </div>
  )
}

export default GridUsersTable