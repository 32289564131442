import { NavLink } from 'react-router-dom'
import styles from './styles.module.scss'
import ArrowIcon from '../../assets/images/arrow-right-icon.svg'

const StatsCard = ({ title, iconUrl, statNumber, linkName, linkUrl }) => {
  return (
    <div className={styles.statsCard}>
      <div className={styles.header}>
        {iconUrl && <img className={styles.icon} src={iconUrl} alt="icon" />}
        <p className={styles.title}>{title}</p>
      </div>

      <div className={styles.bottom}>
        <div className={styles.statNumber}>{statNumber}</div>
        {linkName && linkUrl && (
          <NavLink to={linkUrl} className={styles.link}>
            {linkName}
            {' '}
            <img src={ArrowIcon} alt="" />
          </NavLink>
        )}
      </div>

    </div>
  )
}

export default StatsCard