import styles from './styles.module.scss'
import SettingsCard from '../../components/SettingsCard'
import PersonalIcon from '../../assets/images/settings-icons/personal-icon.svg'
import LoginIcon from '../../assets/images/settings-icons/login-icon.svg'
import BlockchainIcon from '../../assets/images/settings-icons/blockchain-icon.svg'
import GlobalIcon from '../../assets/images/settings-icons/global-icon.svg'
import NotificationIcon from '../../assets/images/settings-icons/notifications-icon.svg'
import PrivacyIcon from '../../assets/images/settings-icons/privacy-icon.svg'

const Settings = () => {
  return (
    <>
      <h2>Admin Settings</h2>
      <div className={styles.wrapper}>
        <SettingsCard
          title="Personal info"
          desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem "
          icon={PersonalIcon}
        />
        <SettingsCard
          title="Login & security"
          desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem "
          icon={LoginIcon}
        />
        <SettingsCard
          title="Blockchain keys"
          desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem "
          icon={BlockchainIcon}
        />
        <SettingsCard
          title="Notifications"
          desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem "
          icon={NotificationIcon}
        />
        <SettingsCard
          title="Global preferences"
          desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem "
          icon={GlobalIcon}
        />
        <SettingsCard
          title="Privacy & sharing"
          desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem "
          icon={PrivacyIcon}
        />
      </div>
    </>

  )
}

export default Settings