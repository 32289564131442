import { useState, useCallback } from 'react'
import { useLoaderData } from 'react-router-dom'

import { useTokenSaleUpdatePrice } from 'hooks/useTokenSales'

import ProgressBar from 'components/ProgressBar'
import ModalUi from 'components/ModalUi'
import InvestorItem from 'components/InvestorItem'
import TransactionItem from 'components/TransactionItem'
import CustomInputUi from 'components/CustomInput'

import { formatNative, formatBlockchainAddress } from 'utils/formatNumber'

import styles from './styles.module.scss'

function formatDate(dateString) {
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  const date = new Date(dateString)
  return date.toLocaleDateString('en-US', options)
}

function getFileNameFromUrl(url) {
  const parts = url.split('/')
  return parts[parts.length - 1]
}

function formatString(str) {
  return str
    .split('_') // Разбиваем строку на массив слов
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Делаем первую букву каждого слова заглавной
    .join(' ') // Объединяем слова обратно в строку с пробелами
}

const TokenSale = () => {
  const {
    tokenSale,
    investors,
    transactions,
  } = useLoaderData()
  const {
    processing: isLoading,
    error: submitError,
    onUpdatePrice,
  } = useTokenSaleUpdatePrice(tokenSale.id)

  const isMusharakah = tokenSale.token_info.musharakah_allowed || tokenSale.token_info.is_musharakah

  // const calculateInvestorPercent = amount => {
  //   return (parseFloat(amount) / parseFloat(tokenSale.token_info.total_supply) * 100).toPrecision(2)
  // }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newPrice, setNewPrice] = useState('')
  const [formError, setFormError] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const calculateTimeLeft = (endDate) => {
    const today = new Date()
    const end = new Date(endDate)
    const timeLeft = end - today
    const daysLeft = Math.round(timeLeft / (1000 * 60 * 60 * 24))
    return daysLeft > 0 ? `${daysLeft} days` : '0 days'
  }

  const handlePriceChange = evt => {
    if (!evt.target.validity.valid) {
      return
    }

    setNewPrice(evt.target.value)
  }

  const handlePriceUpdate = useCallback(async () => {
    setFormError('')
    if (!newPrice) {
      setFormError('New price can not be empty')
      return false
    }

    if (Number(newPrice) === Number(formatNative(tokenSale.token_info.price))) {
      setFormError('The new price must be different from the old one')
      return false
    }

    if (!newPrice || newPrice.trim() === '') {
      setFormError('Value cannot be empty')
      return false
    }

    setIsUpdating(true)

    try {
      await onUpdatePrice(newPrice)
      setTimeout(() => {
        window.location.reload()
      }, 5000)
    } catch (error) {
      if (error.response) {
        setFormError(JSON.stringify(error.response.data))
      } else {
        console.log(error)
      }
    } finally {
      setTimeout(() => {
        window.location.reload()
      }, 5000)
    }

    await onUpdatePrice(newPrice)
  }, [newPrice, onUpdatePrice])

  const pureInvestors = investors.filter(({ is_musharakah_borrower }) => !is_musharakah_borrower)
  const borrower = investors.find(({ is_musharakah_borrower }) => is_musharakah_borrower)

  return (
    <>
      <h2>
        Token sale #
        {tokenSale.id}
      </h2>
      <div className={styles.wrapper}>
        <div className={styles.commonForm}>
          <div className={styles.mainSection}>
            <p className={styles.header}>Tokensale main info</p>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>
                Funding progress (
                {(tokenSale.token_info.total_supply / tokenSale.token_info.hardcap) * 100}
                %)
              </p>
              <ProgressBar
                current={tokenSale.token_info.total_supply}
                total={tokenSale.token_info.hardcap}
                colorBg="#EAEAEA"
                colorMain="#007BFF"
                height={20}
              />
              <p className={styles.progressBarData}>{`${formatNative(tokenSale.token_info.total_supply)} / ${formatNative(tokenSale.token_info.hardcap)}`}</p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Public title</p>
              <p className={styles.innerDesc}>
                {' '}
                {tokenSale.title}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Address</p>
              <p className={styles.innerDesc}>
                {tokenSale.address.address}
                ,
                {' '}
                {tokenSale.address.city}
                ,
                {' '}
                {tokenSale.address.country}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>About the Property</p>
              <p className={styles.innerDesc}>{tokenSale.description}</p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Project Annual Return</p>
              <p className={styles.innerDesc}>
                {`${tokenSale.additional_info.projected_annual_return}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Project Rental Yield</p>
              <p className={styles.innerDesc}>
                {`${tokenSale.additional_info.projected_rental_yield}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Rental Yield</p>
              <p className={styles.innerDesc}>
                {`${tokenSale.additional_info.rental_yield}%`}
              </p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Thumbnail</p>
              <p className={styles.innerDesc}><img className={styles.imgWrapper} src={tokenSale.thumbnail?.url} alt="" /></p>
            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Images</p>
              <div className={styles.innerImages}>
                {
                  tokenSale.images?.map((image) => (
                    <div key={image.url}>
                      <p className={styles.innerDesc}><img className={styles.imgWrapper} src={image.url} alt="" /></p>
                    </div>
                  ))
                }
              </div>

            </div>
            <div className={styles.innerSection}>
              <p className={styles.innerHeader}>Documents</p>
              <div className={styles.innerImages}>
                {
                  tokenSale.documents?.map((doc) => (
                    <div key={doc.url}>
                      <p className={styles.innerDesc}><a className={styles.innerDoc} href={doc.url}>{getFileNameFromUrl(doc.url)}</a></p>
                    </div>
                  ))
                }
              </div>

            </div>
          </div>

          <hr className={styles.separator} />
          { isMusharakah &&  <>
            {' '}
            <div className={styles.mainSection}>
              <p className={styles.header}>Borrower</p>
              {/* change to borrower and remove " ! "  */}
              {borrower ? (
                <InvestorItem key={borrower.id} investor={borrower} tokenSale={tokenSale} />
              ) : (
                <p className={styles.emptyText}>
                  So far there is not a single user who would buy at least 7% of tokens and become a borrower
                </p>
              )}
            </div>
            <hr className={styles.separator} />
          </>}

          <div className={styles.mainSection}>
            <p className={styles.header}>Investors</p>
            {pureInvestors.length > 0 ? (
              pureInvestors.map(investor => (
                <InvestorItem key={investor.id} investor={investor} tokenSale={tokenSale} />
              ))
            ) : (
              <p className={styles.emptyText}>
                There are no investors on this token sale yet
              </p>
            )}
          </div>
          <hr className={styles.separator} />
          {/* TODO::Sections for orders, rent payments, and etc */}

          {/* <div className={styles.mainSection}>
            <p className={styles.header}>Rent Payments !Stub Data!</p>
            {transactions.length > 0 ? (
              transactions.map(tx => (
                <div className={styles.rentTransactions} key={tx.id}><TransactionItem tx={tx} /></div>

              ))
            ) : (
              <p className={styles.emptyText}>
                There are no transactions for rent payments
              </p>
            )}
          </div>
          <hr className={styles.separator} /> */}
          <div className={styles.mainSection}>
            <p className={styles.header}>Last transactions</p>
            {transactions.length > 0 ? (
              transactions.map(tx => (
                <TransactionItem key={tx.id} tx={tx} />
              ))
            ) : (
              <p className={styles.emptyText}>
                There are no transactions on this token sale yet
              </p>
            )}
          </div>
        </div>
        <div className={styles.additional}>
          <div className={styles.helpSection}>
            <div>
              <div className={styles.title}>ID</div>
              <div className={styles.desc}>{tokenSale.id}</div>
            </div>
            <div>
              <div className={styles.title}>Type</div>
              <div className={styles.desc}><span className={styles.typeLabel}>{isMusharakah ? 'Musharakah' : 'Standart'}</span></div>
            </div>
            <div>
              <div className={styles.title}>Token Symbol</div>
              <div className={styles.desc}>{tokenSale.token_info.symbol}</div>
            </div>
            {tokenSale.token_info.contract_address !== '' && <div>
              <div className={styles.title}>Token Contract</div>
              <div className={styles.desc}>
                <a
                  className={styles.contractLink}
                  target="_blank"
                  href={
                    'https://explorer-testnet.edifice.network/address/' +
                    tokenSale.token_info.contract_address
                  }
                  rel="noreferrer"
                >
                  {formatBlockchainAddress(
                    tokenSale.token_info.contract_address,
                  )}
                </a>
              </div>
            </div>}

            <div>
              <div className={styles.title}>Total tokens</div>
              <div className={styles.desc}>{`${formatNative(tokenSale.token_info.hardcap)}`}</div>
            </div>
            <div>
              <div className={styles.title}>Initial price</div>
              <div className={styles.desc}>
                {`${formatNative(tokenSale.token_info.original_price)} DNR`}
              </div>
            </div>
            <div>
              <div className={styles.title}>Current price</div>
              <div className={styles.desc}>
                {`${formatNative(tokenSale.token_info.price)} DNR`}
              </div>
            </div>
            <div>
              <div className={styles.title}>Time left</div>
              <div className={styles.desc}>
                {calculateTimeLeft(tokenSale.token_info.sale_end_at)}
              </div>
            </div>
            <div>
              <div className={styles.title}>Tokensale start at</div>
              <div className={styles.desc}>
                {formatDate(tokenSale.token_info.sale_start_at)}
              </div>
            </div>
            <div>
              <div className={styles.title}>Tokensale end at</div>
              <div className={styles.desc}>
                {formatDate(tokenSale.token_info.sale_end_at)}
              </div>
            </div>
            <div>
              <div className={styles.title}>Creation date</div>
              <div className={styles.desc}>
                {formatDate(tokenSale.created_at)}
              </div>
            </div>
            <div>
              <div className={styles.title}>Status</div>
              <div className={styles.desc}>
                {formatString(tokenSale.status)}
              </div>
            </div>
          </div>
          { (isMusharakah && tokenSale.status === 'funded') && <div onClick={openModal} className={styles.changePriceBtn}>Change token price</div>}
          <div className={styles.submitBtn}>Close and refund</div>
        </div>
      </div>
      <ModalUi isOpen={isModalOpen} onClose={closeModal}>
        <div className={styles.modalWrapper}>
          <h4 className={styles.modalHeader}>
            {`Do you really want to change the price for the ${tokenSale.token_info.symbol} token in the token sale?`}
          </h4>
          <div className={styles.infoBanner}>
            {`Current price ${formatNative(tokenSale.token_info.price)} DNR`}
          </div>
          <div className={styles.modalContent}>
            <CustomInputUi
              label="New Token Price"
              name="newPrice"
              value={newPrice}
              placeholder="New Token Price in DNR"
              onChange={handlePriceChange}
              disabled={isUpdating}
              autoComplete="off"
              pattern="^([0-9]+(?:[.,][0-9]*)?)$"
              hint="Сannot be zero or negative number"
            />
          </div>
          {(formError || submitError) ? (
            <div className={styles.modalError}>{formError || submitError}</div>
          ) : null}
          <div className={styles.modalActions}>
            <button disabled={isUpdating} onClick={handlePriceUpdate} className={styles.modalAccept}>
              {isUpdating || isLoading ? 'Updating...' : 'Yes, I want to change the price'}
            </button>
            <button onClick={closeModal} className={styles.modalDecline}>
              Cancel
            </button>
          </div>
        </div>
      </ModalUi>
    </>
  )
}

export default TokenSale
