import styles from './styles.module.scss'
import { formatDate } from 'utils/formatDate'
import { NavLink } from 'react-router-dom'

const GridAdminTable = ({ admins }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.gridTable}>
        <div className={styles.gridHeaderWrapper}>
          <div className={styles.gridHeader}>ID</div>
          <div className={styles.gridHeader}>Login</div>
          <div className={styles.gridHeader}>Role</div>
          <div className={`${styles.gridHeader} ${styles.txtRightAlign}`}>Last updated</div>
        </div>

        {admins.map((admin) =>
          (<NavLink className={styles.gridItemWrapper} key={admin.id} to={`/admin/${admin.id}`}>
            <div className={`${styles.gridItem} ${styles.txtLeftAlign}`}>
              {admin.id}
            </div>
            <div className={styles.gridItem}>{admin.login}</div>
            <div className={styles.gridItem}>{admin.roles[0]}</div>
            <div className={`${styles.gridItem} ${styles.date} ${styles.txtRightAlign}`}>{formatDate(admin.updated_at)}</div>
          </NavLink>),
        )}
      </div>

    </div>
  )
}

export default GridAdminTable