import { NavLink, useParams } from 'react-router-dom'
import { useAdmin } from '../../hooks/useAdmins'
import styles from './styles.module.scss'
import HintIcon from '../../assets/images/hint-icon.svg'
import GlobalPreloader from 'components/GlobalPreloader'

const Admin = () => {
  const { id } = useParams()
  const { admin, isLoading, error } = useAdmin(id)

  if (isLoading) return <GlobalPreloader />
  if (error) return <div>
    Error:
    {' '}
    {error}
  </div>

  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', options)
  }

  return (
    <>
      <h2>
        Information about
        {' '}
        {admin.login}
      </h2>
      <div className={styles.wrapper}>
        <div className={styles.commonForm}>
          <div className={styles.mainSection}>
            <p className={styles.header}>Main Information</p>
            <p className={styles.description}>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
            <div className={styles.formSection}>
              <div>
                <div className={styles.inputWrapper}>
                  <div className={styles.label}>ID</div>
                  <p className={styles.formInput}>{admin.id}</p>
                </div>
                <div className={styles.inputWrapper}>
                  <div className={styles.label}>Login</div>
                  <p className={styles.formInput}>{admin.login}</p>
                </div>
                <div className={styles.inputWrapper}>
                  <div className={styles.label}>Roles</div>
                  <p className={styles.formInput}>{admin.roles}</p>
                </div>
                <div className={styles.inputWrapper}>
                  <div className={styles.label}>Created at</div>
                  <p className={styles.formInput}>
                    {formatDate(admin.created_at)}
                  </p>
                </div>
                <div className={styles.inputWrapper}>
                  <div className={styles.label}>Updated at</div>
                  <p className={styles.formInput}>
                    {formatDate(admin.updated_at)}
                  </p>
                </div>
              </div>
              <hr className={styles.separator} />
              <NavLink
                className={styles.submitBtn}
                to={`/edit-admin/${admin.id}`}
              >
                Edit admin
              </NavLink>
            </div>
          </div>
        </div>
        <div className={styles.additional}>
          <div className={styles.helpSection}>
            <img src={HintIcon} alt="" />
            <div className={styles.title}>
              Title for the section with additional information
            </div>
            <div className={styles.desc}>
              {'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\', making it look like readable English.'}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Admin
