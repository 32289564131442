import { useState, useEffect } from 'react'
import UserService from '../api/UsersService'

const useUsers = () => {
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchAUsers = async () => {
      try {
        const data = await UserService.getUsers()
        setUsers(data)
      } catch (error) {
        setError('Failed to fetch users')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAUsers()
  }, [])

  return { users, isLoading, error }
}

const useUser = (id) => {
  const [user, setUser] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const data = await UserService.getUser(id)
        setUser(data)
      } catch (error) {
        setError('Failed to fetch user')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchUser()
  }, [])

  return { user, isLoading, error }
}

export { useUsers, useUser }