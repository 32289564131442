import GlobalPreloader from 'components/GlobalPreloader'
import { useUsers } from '../../hooks/useUsers'
import GridUsersTable from 'components/GridUsersTable'

const Users = () => {
  const { users, isLoading, error } = useUsers()
  console.log(users)

  if (isLoading) {
    return <GlobalPreloader />
  }

  if (error) {
    return <div>
      Error:
      {' '}
      {error}
    </div>
  }

  return (
    <div>
      <h2>
        Users (
        {users.length}
        )
      </h2>
      <GridUsersTable users={users} />
    </div>
  )
}

export default Users
