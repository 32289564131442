import api from './api'

const UserService = {
  getUsers: async () => {
    const response = await api.get('/users')
    return response.data.data.items
  },
  getUser: async (id) => {
    const response = await api.get(`/users/${id}`)
    return response.data.data
  },
  fetchTokenAssets: async id => {
    const { data } = await api.get(`/users/${id}/assets`)
    return data.data
  },
  fetchTokenSalesStatistics: async id => {
    const { data } = await api.get(`/users/${id}/token-sales/statistics`)
    return data.data
  },
  fetchTransactions: async id => {
    const { data } = await api.get(`/users/${id}/transactions`)
    return data.data
  },
}

export default UserService