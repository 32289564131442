import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'
import AdminService from '../../api/AdminsService'
import HintIcon from '../../assets/images/hint-icon.svg'

const NewAdmin = () => {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('super_admin')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const isFormValid = () => {
    return login && password && role
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!isFormValid()) {
      setError('All fields are required.')
      return
    }

    setIsLoading(true)
    setError('')

    try {
      await AdminService.createAdmin(login, password, role)
      navigate('/admins')
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to create admin.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <h2>Create new Admin</h2>
      <div className={styles.wrapper}>
        <div className={styles.commonForm}>
          <div className={styles.mainSection}>
            <p className={styles.header}>Main Information</p>
            <p className={styles.description}>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
            <div className={styles.formSection}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className={styles.inputWrapper}>
                  <label>Login:</label>
                  <input
                    autoComplete="off"
                    className={styles.formInput}
                    type="text"
                    value={login}
                    placeholder="Login"
                    onChange={(e) => setLogin(e.target.value)}
                    disabled={isLoading}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <label>Password:</label>
                  <input
                    autoComplete="new-password"
                    className={styles.formInput}
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={isLoading}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <label>Role:</label>
                  <select
                    className={styles.formInput}
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    disabled={isLoading}
                  >
                    <option value="super_admin">Super Admin</option>
                    <option value="admin">Admin</option>
                    <option value="manager">Manager</option>
                  </select>
                </div>

                <hr className={styles.separator} />
                {error && <div className={styles.errorWrapper}>{error}</div>}
                <button
                  className={styles.submitBtn}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? 'Creating...' : 'Create Admin'}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className={styles.additional}>
          <div className={styles.helpSection}>
            <img src={HintIcon} alt="" />
            <div className={styles.title}>
              Title for the section with additional information
            </div>
            <div className={styles.desc}>
              {'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\', making it look like readable English.'}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewAdmin
