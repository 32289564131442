import styles from './styles.module.scss'

const MainContainerUi = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default MainContainerUi