import { NavLink } from 'react-router-dom'

import BackgroundImage from '../BackgroundImage'

import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'
import Status from 'components/Status'

function UserTokenSaleCard({ tokenSaleStat }) {
  const dnrAmount = BigInt(tokenSaleStat.amount) * BigInt(tokenSaleStat.token_sale.token_info.price) / BigInt(1e18)
  return (
    <div className={styles.wrapper}>
      <div className={styles.mainInfo}>
        <div className={styles.image}>
          <BackgroundImage imageUrl={tokenSaleStat.token_sale.thumbnail?.url} />
        </div>
        <div className={styles.tokensaleInfo}>
          <div className={styles.status}>
            <Status status={tokenSaleStat.token_sale.status} />
          </div>
          <p className={styles.title}>
            <NavLink to={`/tokensale/${tokenSaleStat.token_sale.id}`}>
              {tokenSaleStat.token_sale.title}
            </NavLink>
          </p>
          <p className={styles.address}>
            {tokenSaleStat.token_sale.token_info.contract_address}
          </p>
        </div>
      </div>
      <div className={styles.tokenInfo}>
        <p className={styles.retAmount}>
          {formatNative(tokenSaleStat.amount)}
          {' '}
          <span>
            {tokenSaleStat.token_sale.token_info.symbol}
          </span>
        </p>
        <p className={styles.DnrAmount}>
          {formatNative(dnrAmount)}
          {' '}
          <span>DNR</span>
        </p>
      </div>
    </div>
  )
}

export default UserTokenSaleCard
