import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'

const CustomTable = ({ width, columns, data }) => {
  const navigate = useNavigate()

  const handleRowClick = (row) => {
    // Проверяем, существует ли ссылка в объекте ID
    const link = row.ID && row.ID.link
    if (link) {
      navigate(link)
    }
  }

  return (
    <table className={styles.table} style={{ width }}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={Object.keys(column)[0]}
              style={{ width: `${Object.values(column)[0]}px` }}
            >
              {Object.keys(column)[0]}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr
            key={rowIndex}
            onClick={() => handleRowClick(row)}
            className={row.ID && row.ID.link ? styles.clickableRow : ''}
          >
            {columns.map((column) => {
              const key = Object.keys(column)[0]
              const cellData = row[key]
              return <td key={key}>{cellData.text}</td>
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default CustomTable
