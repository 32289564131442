import GlobalPreloader from 'components/GlobalPreloader'
import { useTokenSales } from '../../hooks/useTokenSales'
import GridTokenSalesTable from 'components/GridTokenSalesTable'

const TokenSale = () => {
  const { tokenSales, isTokenSalesLoading, error } = useTokenSales()

  if (isTokenSalesLoading) {
    return <GlobalPreloader />
  }

  if (error) {
    return <div>
      Error:
      {' '}
      {error}
    </div>
  }

  return (
    <>
      <h2>
        Musharakah Tokensales
        {tokenSales.filter((tokenSale) => tokenSale.token_info.musharakah_allowed).length > 0 ?
          (tokenSales.filter((tokenSale) => tokenSale.token_info.musharakah_allowed).length)
          :
          (' ')
        }

      </h2>
      {tokenSales.filter((tokenSale) => tokenSale.token_info.musharakah_allowed).length > 0 ?
        (<GridTokenSalesTable tokenSales={tokenSales.filter((tokenSale) => tokenSale.token_info.musharakah_allowed)} />)
        :
        ('no token sales found')
      }

    </>
  )
}

export default TokenSale