import { useState, useRef } from 'react'
import styles from './styles.module.scss'
import UploadDocIcon from 'assets/images/upload-doc.svg'

const ThumbnailUploader = ({ onThumbnailChange }) => {
  const [thumbnail, setThumbnail] = useState(null)
  const inputRef = useRef(null)

  const handleThumbnailClick = () => {
    inputRef.current.click()
  }

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0]
    setThumbnail(file)
    if (onThumbnailChange) {
      onThumbnailChange(file)
    }
  }

  const handleRemoveThumbnail = () => {
    setThumbnail(null)
    inputRef.current.value = ''
    if (onThumbnailChange) {
      onThumbnailChange(null)
    }
  }

  return (
    <div className={styles.inputWrapper}>
      <p className={styles.label}>Thumbnail upload:</p>
      <div
        className={styles.uploadThumbnailInput}
      >
        {thumbnail ? (
          <div className={styles.imgUploaded}>
            <img
              src={URL.createObjectURL(thumbnail)}
              alt="Thumbnail"
            />
          </div>
        ) : (
          <div className={styles.uploadAction}>
            <div className={styles.iconWrapper}>
              <img src={UploadDocIcon} alt="" />
            </div>
            <p>
              {' '}
              <span onClick={handleThumbnailClick} className={styles.actionLink}>Click to upload thumbnail</span>
              {' '}
              (JPEG, PNG)
            </p>
            <p>Max. File Size: 10MB</p>
          </div>

        )}
      </div>
      {/* {thumbnail && (
        <div
          className={styles.removeBtn}
          onClick={handleRemoveThumbnail}
        >
          Remove Thumbnail
        </div>
      )} */}
      <input
        type="file"
        ref={inputRef}
        accept="image/png, image/jpeg"
        onChange={handleThumbnailChange}
        style={{ display: 'none' }}
      />
      <div className={styles.bottomLinks}>
        <p onClick={handleThumbnailClick} className={styles.actionLink}>Click to upload thumbnail</p>
        {thumbnail && (
          <p onClick={handleRemoveThumbnail} className={styles.removeLink}>Remove thumbnail</p>
        )}
      </div>

    </div>
  )
}

export default ThumbnailUploader
