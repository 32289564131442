import Status from 'components/Status'
import styles from './styles.module.scss'
import { formatDate } from 'utils/formatDate'
import { formatNative} from 'utils/formatNumber'
import { NavLink } from 'react-router-dom'

const GridTokenSalesTable = ({ tokenSales }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.gridTable}>
        <div className={styles.gridHeaderWrapper}>
          <div className={styles.gridHeader}>ID / title</div>
          <div className={styles.gridHeader}>Status</div>
          <div className={styles.gridHeader}>Start Date</div>
          <div className={styles.gridHeader}>End Date</div>
          <div className={`${styles.gridHeader} ${styles.txtRightAlign}`}>Funding</div>
        </div>

        {tokenSales.map((tokenSale) =>
          (<NavLink className={styles.gridItemWrapper} key={tokenSale.id} to={`/tokensale/${tokenSale.id}`}>
            <div className={`${styles.gridItem} ${styles.txtLeftAlign}`}>
              <div className={styles.thumbWrapper}><img src={tokenSale.thumbnail?.url} alt="" /></div>
              <div className={styles.columnWrapper}>
                <p className={styles.id}>{`ID:${tokenSale.id}`}</p>
                <p className={styles.title}>{tokenSale.title}</p>
                <p className={styles.symbol}>{`[${tokenSale.token_info.symbol}]`}</p>
              </div>
            </div>
            <div className={styles.gridItem}><Status status={tokenSale.status} /></div>
            <div className={`${styles.gridItem} ${styles.date}`}>{formatDate(tokenSale.token_info.sale_start_at)}</div>
            <div className={`${styles.gridItem} ${styles.date}`}>{formatDate(tokenSale.token_info.sale_end_at)}</div>
            {tokenSale.token_info.contract_address !== '' ?
              <div className={`${styles.gridItem} ${styles.fundProgress}`}>
                <p className={styles.totalSupply}>{`${formatNative(tokenSale.token_info.total_supply)} /`}</p>
                <p className={styles.hardcap}>{formatNative(tokenSale.token_info.hardcap)}</p>
              </div>
              : <div className={styles.gridItem}>
                <p className={styles.txtRightAlign}>No info</p>
              </div>}

          </NavLink>),
        )}
      </div>

    </div>
  )
}

export default GridTokenSalesTable