import { useState, useEffect, useCallback } from 'react'

import TokenSaleService from '../api/TokenSaleService'
import { parseEther } from 'ethers'

export function useTokenSales() {
  const [tokenSales, setTokenSales] = useState([])
  const [isTokenSalesLoading, setTokenSalesIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchTokenSales = async () => {
      try {
        const data = await TokenSaleService.getTokenSales()
        setTokenSales(data)
      } catch (error) {
        setError('Failed to fetch tokensales')
        console.error(error)
      } finally {
        setTokenSalesIsLoading(false)
      }
    }

    fetchTokenSales()
  }, [])

  return { tokenSales, isTokenSalesLoading, error }
}

export function useTokenSale(id) {
  const [tokenSale, setTokenSale] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchTokenSale = async () => {
      try {
        const data = await TokenSaleService.getTokenSale(id)
        setTokenSale(data)
      } catch (error) {
        setError('Failed to fetch tokensales')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchTokenSale()
  }, [])

  return { tokenSale, isLoading, error }
}

export function useTokenSaleRequests() {
  const [tokenSaleRequests, setTokenSaleRequests] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchTokenSaleRequests = async () => {
      try {
        const data = await TokenSaleService.getTokenSaleRequests()
        setTokenSaleRequests(data)
      } catch (error) {
        setError('Failed to fetch tokensales Requests')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchTokenSaleRequests()
  }, [])

  return { tokenSaleRequests, isLoading, error }
}

export function useTokenSaleRequest(id) {
  const [tokenSaleRequest, setTokenSaleRequests] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchTokenSaleRequest = async () => {
      try {
        const data = await TokenSaleService.getTokenSaleRequest(id)
        setTokenSaleRequests(data)
      } catch (error) {
        setError('Failed to fetch tokensales Requests')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchTokenSaleRequest()
  }, [])

  return { tokenSaleRequest, isLoading, error }
}

export function useTokenSaleUpdatePrice(id) {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)

  const onUpdatePrice = useCallback(async price => {
    setProcessing(true)
    setError(null)
    try {
      await TokenSaleService.updatePrice(id, parseEther(price).toString())
    } catch (error) {
      setError('Failed to update price')
      console.error(error)
    } finally {
      setProcessing(false)
    }
  }, [])

  return { processing, error, onUpdatePrice }
}
