import GlobalPreloader from 'components/GlobalPreloader'
import RequestedTokenSaleUi from '../../components/RequestedTokenSaleUi'
import { useTokenSaleRequests } from '../../hooks/useTokenSales'

function calcTotalPrice(price, hardcap) {
  return price * hardcap / 1000000000000000000
}

const TokenSaleRequests = () => {
  const { tokenSaleRequests, isLoading, error } = useTokenSaleRequests()

  if (isLoading) {
    return <GlobalPreloader />
  }

  if (error) {
    return <div>
      Error:
      {' '}
      {error}
    </div>
  }

  return (
    <div>
      <h2>
        Tokensales Requests (
        {tokenSaleRequests.length}
        )
      </h2>

      {tokenSaleRequests.map(tokenSaleRequest => (
        <RequestedTokenSaleUi
          key={tokenSaleRequest.id}
          id={tokenSaleRequest.id}
          type="house"
          address={tokenSaleRequest.address.address}
          location={`${tokenSaleRequest.address.city} ${tokenSaleRequest.address.country}`}
          price={calcTotalPrice(tokenSaleRequest.token_info.price, tokenSaleRequest.token_info.hardcap)}
          imageUrl={tokenSaleRequest.thumbnail?.url}
        />
      ))}
    </div>
  )
}

export default TokenSaleRequests

// RequestedTokenSaleUi
