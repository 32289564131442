import styles from './styles.module.scss'

const Status = ({ status}) => {

  let color, bgColor, text

  switch (status) {
  case 'verified':
    color = '#fff'
    bgColor = '#3C2696'
    text = 'Verified'
    break
  case 'published':
    color = '#F7B82E'
    bgColor = '#FFF6E0'
    text = 'Published'
    break
  case 'active':
    color = '#8C71FF'
    bgColor = '#F9F8FF'
    text = 'Active'
    break
  case 'wait_contract_deploy':
    color = '#E92E2E'
    bgColor = '#FADEDE'
    text = 'Awaiting SC Deploy'
    break
  case 'funded':
    color = '#fff'
    bgColor = '#222'
    text = 'Funded'
    break
  case 'unverified':
    color = '#757575'
    bgColor = '#E0E0E0'
    text = 'Not Verified'
    break
  case 'closed':
    color = '#757575'
    bgColor = '#E0E0E0'
    text = 'Closed'
    break
  default:
    color = '#757575' // Серый цвет как пример
    bgColor = '#E0E0E0' // Светло-серый фон как пример
    text = 'Status Unknown' // Текст для неизвестного статуса
  }

  const inlineStyle = {
    color: color,
    backgroundColor: bgColor,
  }

  return (
    <div className={styles.wrapper} style={inlineStyle}>
      {text}
    </div>
  )
}

export default Status