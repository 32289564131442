import { useAdmins } from '../../hooks/useAdmins'
import { NavLink } from 'react-router-dom'
import NewIcon from '../../assets/images/sidebar-icons/new-icon.svg'
import styles from './styles.module.scss'
import GlobalPreloader from 'components/GlobalPreloader'
import GridAdminTable from 'components/GridAdminTable'

const AdminsPage = () => {
  const { admins, isLoading, error } = useAdmins()

  if (isLoading) {
    return <GlobalPreloader />
  }

  if (error) {
    return <div>
      Error:
      {' '}
      {error}
    </div>
  }

  return (
    <div>
      <div className={styles.headerWrapper}>
        <h2>
          Admins (
          {admins.length}
          )
        </h2>
        <NavLink to="/new-admin" className={styles.createNewBnt}>
          <span className={styles.iconWrapper}>
            <img src={NewIcon} alt="" />
          </span>
          <span>Create admin</span>
        </NavLink>
      </div>

      <GridAdminTable admins={admins} />
    </div>
  )
}

export default AdminsPage
