import styles from './styles.module.scss'

const SettingsCard = ({ icon, title, desc }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <img src={icon} alt="" />
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.title}>
          {title}
        </p>
        <p className={styles.desc}>
          {desc}
        </p>
      </div>
    </div>
  )
}

export default SettingsCard