import { ethers } from 'ethers'
import { Decimal } from 'decimal.js-light'

export function formatNumber(input, digits = 2, units = 6) {
  let number
  if (typeof input === 'number') {
    number = input
  } else {
    number = parseFloat(ethers.formatUnits(String(input), units))
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(number)
}

export function formatNative(input, digits = 2) {
  const stringValue = String(input)

  const ethValue = new Decimal(stringValue).dividedBy(new Decimal('1e18'))

  const roundedEthValue = ethValue.toFixed(digits, Decimal.ROUND_DOWN)

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(roundedEthValue)
}

export function formatUSDT(input, digits = 2) {
  return formatNumber(input, digits, 6)
}

export function formatBlockchainAddress(address) {
  if (typeof address !== 'string') {
    throw new Error('Input must be a string')
  }

  // Проверяем, что строка соответствует формату блокчейн адреса
  const addressRegex = /^0x[0-9a-fA-F]{40}$/
  if (!addressRegex.test(address)) {
    throw new Error('Invalid blockchain address format')
  }

  // Извлекаем первые 8 символов и последние 4 символа
  const prefix = address.substring(0, 8)
  const suffix = address.substring(address.length - 4)

  // Возвращаем отформатированную строку
  return `${prefix}...${suffix}`
}

