import styles from './styles.module.scss'
import { useLoaderData } from 'react-router-dom'
import moment from 'moment'

import UserTokenSaleCard from 'components/UserTokenSaleCard'

import { formatBlockchainAddress } from 'utils/formatNumber'
import TransactionItem from 'components/TransactionItem'
import WalletAssetItem from 'components/WalletAssetItem'

const User = () => {
  const {
    user,
    tokenAssets,
    tokenSalesStatistics,
    transactions,
  } = useLoaderData()

  return (
    <>
      <h2>{user.full_name}</h2>
      <div className={styles.wrapper}>
        <div className={styles.commonForm}>
          <div className={styles.mainSection}>
            <p className={styles.header}>Tokensales statistic</p>
            {tokenSalesStatistics.length ? (
              tokenSalesStatistics.map(stat => (
                <UserTokenSaleCard
                  key={stat.token_sale.id}
                  tokenSaleStat={stat}
                />
              ))
            ) : (
              <p className={styles.emptyText}>
                There are no token sales
              </p>
            )}
          </div>
          <hr className={styles.separator} />
          <div className={styles.mainSection}>
            <p className={styles.header}>Wallet assets</p>
            {tokenAssets.length > 0 ? (
              tokenAssets.map(asset => (
                <WalletAssetItem asset={asset} key={asset.token_symbol} />
              ))
            ) : (
              <p className={styles.emptyText}>
                There are no assets
              </p>
            )}
          </div>
          <hr className={styles.separator} />
          <div className={styles.mainSection}>
            <p className={styles.header}>Last transactions</p>
            {transactions.length > 0 ? (
              transactions.map(tx => (
                <TransactionItem key={tx.id} tx={tx} />
              ))
            ) : (
              <p className={styles.emptyText}>
                There are no transactions on this token sale yet
              </p>
            )}
          </div>
        </div>
        <div className={styles.additional}>
          <div className={styles.helpSection}>
            <div>
              <div className={styles.title}>ID</div>
              <div className={styles.desc}>{user.id}</div>
            </div>
            <div>
              <div className={styles.title}>Email</div>
              <div className={styles.desc}>{user.email}</div>
            </div>
            {
              user.eth_address !== '' && <div>
                <div className={styles.title}>Wallet address</div>
                <div className={styles.desc}>
                  {' '}
                  <a
                    className={styles.contractLink}
                    target="_blank"
                    href={
                      'https://explorer-testnet.edifice.network/address/' +
                    user.eth_address
                    }
                    rel="noreferrer"
                  >
                    {formatBlockchainAddress(
                      user.eth_address,
                    )}
                  </a>
                </div>
              </div>
            }

            <div>
              <div className={styles.title}>Wallet whitelisted</div>
              <div className={styles.desc}>{user.eth_address_whitelisted.toString()}</div>
            </div>
            <div>
              <div className={styles.title}>Registration date</div>
              <div className={styles.desc}>{moment(user.created_at).format('DD MMMM, YYYY')}</div>
            </div>
            <div>
              <div className={styles.title}>Last login</div>
              <div className={styles.desc}>{moment(user.updated_at).format('DD MMMM, YYYY')}</div>
            </div>
            <div>
              <div className={styles.title}>Email confirm</div>
              <div className={styles.desc}>{user.email_confirmed.toString()}</div>
            </div>
            <div>
              <div className={styles.title}>KYC verified</div>
              <div className={styles.desc}>{user.status}</div>
            </div>
          </div>

          <div className={styles.submitBtn}>
            <a href={`mailto:${user.email}`}>Message</a>
          </div>
          <div className={styles.blockBtn}>Block</div>
        </div>
      </div>
    </>
  )
}
export default User
