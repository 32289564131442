import styles from './styles.module.scss'
import moment from 'moment'
import { formatNative } from 'utils/formatNumber'

const TransactionItem = ({ tx }) => {
  return (
    <div key={tx.hash} className={styles.txSection}>
      <div className={styles.txInfo}>
        <div className={styles.dateWrapper}>
          <div className={styles.date}>
            {moment(tx.created_at).format('DD, MMM')}
          </div>
          <div className={styles.time}>
            {moment(tx.created_at).format('HH:mm:ss')}
          </div>
        </div>
        <div className={styles.txData}>
          <p className={styles.type}>
            {tx.type}
          </p>
          <p className={styles.address}>
            <a href={`https://explorer-testnet.edifice.network/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer">
              {tx.hash}
            </a>
          </p>
        </div>
      </div>
      {tx.data.amount && <div className={styles.tokensAmount}>
        <p className={styles.amount}>
          {formatNative(tx.data.amount)}
        </p>

        <p className={styles.symbol}>DNR</p>
      </div>}

    </div>
  )
}

export default TransactionItem