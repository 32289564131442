import { useState, useRef } from 'react'
import styles from './styles.module.scss'
import UploadDocIcon from 'assets/images/upload-doc.svg'
import CloseWhiteIcon from 'assets/images/close-white.svg'

const ImagesUploader = ({ onImagesChange }) => {
  const [images, setImages] = useState([])
  const inputRef = useRef(null)

  const handleImagesClick = () => {
    inputRef.current.click()
  }

  const handleImagesChange = (event) => {
    const newImages = Array.from(event.target.files)
    setImages(prevImages => [...prevImages, ...newImages])
    if (onImagesChange) {
      onImagesChange([...images, ...newImages])
    }
  }

  const handleRemoveImage = (imageToRemove, event) => {
    event.stopPropagation()
    const filteredImages = images.filter(image => image !== imageToRemove)
    setImages(filteredImages)
    if (onImagesChange) {
      onImagesChange(filteredImages)
    }
  }

  const handleRemoveAllImages = () => {
    setImages([])
    if (onImagesChange) {
      onImagesChange([])
    }
  }

  return (
    <div className={styles.inputWrapper}>
      <p className={styles.label}>Images upload:</p>
      <div className={styles.uploadedImages}>
        <div className={styles.uploadedImgsWrapper}>
          {images.length > 0
            ? images.map((image, index) => (
              <div className={styles.uploadedImage} key={image.name}>
                <div onClick={(e) => handleRemoveImage(image, e)} className={styles.removeActionBtn}>
                  <img src={CloseWhiteIcon} alt="" />
                </div>
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Image ${index}`}
                />
              </div>
            ))
            : (<div className={styles.uploadAction}>
              <div className={styles.iconWrapper}>
                <img src={UploadDocIcon} alt="" />
              </div>
              <p>
                <span onClick={handleImagesClick} className={styles.actionLink}>Click to upload images</span>
                {' '}
                (JPEG, PNG)
              </p>
              <p>Max. File Size: 10MB</p>
            </div>)}
        </div>
      </div>
      <input
        type="file"
        ref={inputRef}
        accept="image/png, image/jpeg"
        multiple
        onChange={handleImagesChange}
        style={{ display: 'none' }}
      />
      {images.length > 0
        ? (<div className={styles.bottomLinks}>
          <p onClick={handleImagesClick} className={styles.actionLink}>Click to upload more images</p>
          <p onClick={handleRemoveAllImages} className={styles.removeLink}>Remove all images</p>
        </div>)
        : (<p onClick={handleImagesClick} className={styles.actionLink}>Click to upload images</p>)
      }

    </div>
  )
}

export default ImagesUploader
