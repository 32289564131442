function ProgressBar({ current, total, colorBg, colorMain, height }) {
  const percentage = (current / total) * 100

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: colorBg,
        height: `${height}px`,
        borderRadius: '4px',
      }}
    >
      <div
        style={{
          width: `${percentage}%`,
          backgroundColor: colorMain,
          height: '100%',
          borderRadius: '4px',
        }}
      >
      </div>
    </div>
  )
}

export default ProgressBar
