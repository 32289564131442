import api from './api'

const AdminService = {
  getAdmins: async () => {
    const response = await api.get('/admins')
    return response.data.data
  },
  createAdmin: async (login, password, role) => {
    const response = await api.post('/admins', { login, password, role })
    return response.data.data
  },
  getAdmin: async (id) => {
    const response = await api.get(`/admins/${id}`)
    return response.data.data
  },
  updateAdmin: async (id, password, role) => {
    const response = await api.put(`/admins/${id}`, { password, role })
    return response.data.data
  },
  deleteAdmin: async (id) => {
    const response = await api.delete(`/admins/${id}`)
    return response.data.data
  },
}

export default AdminService