import styles from './styles.module.scss'
import { NavLink } from 'react-router-dom'
import { formatNative } from 'utils/formatNumber'

const InvestorItem = ({ investor, tokenSale}) => {
  return (
    <NavLink to={`/user/${investor.id}`} className={styles.investorSection}>
      <div className={styles.investorInfo}>
        <div className={styles.investorName}>
          <div className={styles.name}>
            {investor.full_name}
          </div>
          <p className={styles.email}>
            {investor.email}
          </p>
        </div>
      </div>
      <div className={styles.tokensAmount}>
        <p className={styles.amount}>
          {formatNative(investor.amount)}
        </p>
        <p className={styles.symbol}>
          {tokenSale.token_info.symbol}
        </p>
      </div>
    </NavLink>
  )
}

export default InvestorItem