import styles from './styles.module.scss'
import { formatNative } from 'utils/formatNumber'

const WalletAssetItem = ({asset}) => {
  return (
    <div key={asset.token_symbol} className={styles.walletWrapper}>
      <p className={styles.assetName}>
        {asset.token_symbol}
      </p>
      <p className={styles.assetAmount}>
        {formatNative(asset.amount)}
      </p>
    </div>
  )
}

export default WalletAssetItem