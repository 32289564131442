import styles from './styles.module.scss'

const InfoBanner = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Test mode</p>
      <p className={styles.desc}>
        In order for new users to have a balance of 500 DNR, top up the balance of this address - 0x4aD841Da72BEb99bECd298426972d95c83F8A2fF
      </p>
    </div>
  )
}

export default InfoBanner