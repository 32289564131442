import styles from './styles.module.scss'

import LogoutIcon from '../../assets/images/header-icons/logout.svg'
import SettingsIcon from '../../assets/images/header-icons/settings.svg'
import NotificationsIcon from '../../assets/images/header-icons/notifications.svg'
import { NavLink } from 'react-router-dom'
import useSession from '../../hooks/useSession'

const HeaderMenu = () => {
  const { session, isLoading, error } = useSession()

  if (isLoading) {
    return <div>Loading session...</div>
  }

  if (error) {
    return <div>
      Error:
      {' '}
      {error}
    </div>
  }

  const logout = () => {
    localStorage.removeItem('token')
    window.location.href = '/login'
  }

  return (
    <div className={styles.wrapper}>
      <div onClick={logout} className={styles.navItem}>
        <img src={LogoutIcon} alt="" />
      </div>
      <div className={styles.navItem}>
        <NavLink to="/settings">
          <div className={styles.adminWrapper}>
            <div className={styles.adminInfoWrapper}>
              <p className={styles.name}>
                {' '}
                {session.login}
                {' '}
              </p>
              <p className={styles.email}>{session.roles[0]}</p>
            </div>
            <img src={SettingsIcon} alt="" />
          </div>
        </NavLink>
      </div>
      <div className={styles.navItem}>
        <img src={NotificationsIcon} alt="" />
      </div>
    </div>
  )
}

export default HeaderMenu
